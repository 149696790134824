<template>
  <v-expansion-panel>
    <v-expansion-panel-header>{{tindex+1}}. <span class="primary--text"> {{topic.name}}</span></v-expansion-panel-header>
    <v-expansion-panel-content> Some content </v-expansion-panel-content>
    <v-toolbar flat>
      <v-spacer />
      <v-btn icon @click="_deleteTopic">
        <v-icon>mdi-trash-can-outline</v-icon>
      </v-btn>

      <v-btn icon @click="_addTopic">
        <v-icon>mdi-plus-circle-outline</v-icon>
      </v-btn>

      <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </v-toolbar>
  </v-expansion-panel>
</template>

<script>
export default {
  name: "Topic",
  data: () => ({}),
  props: {
    topic: {
      type: Object,
      required: true,
    },
    tindex:Number
  },
  methods: {
    _deleteTopic(index){

    },
    _readTopic() {
      var docRef = db.collection("courses").doc("SF");
      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            console.log("Document data:", doc.data());
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    },
  },
  created() {},
};
</script>
